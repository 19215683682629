
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'scheduling'
})
export default class BraceletList extends Vue {
  private tableData =[]
  private dateRange: Array<string> = []
  private dateList: Array<string> = []

  private info: {projectId: string}= {
    projectId: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private pickMinTime: any = null
  created () {
    this.initTime()
    this.getData()
  }

  get projectList () {
    return this.$store.state.projectList
  }

  initTime () {
    this.dateRange = [this.$dayjs().subtract(6, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
    this.getDateList()
  }

  // 获取表头
  getDateList () {
    this.dateList = []
    const arr = []
    const startDay = new Date(this.dateRange[0])
    const endDay = new Date(this.dateRange[1])
    const start = startDay.getTime() - 24 * 60 * 60 * 1000
    const end = endDay.getTime() - 24 * 60 * 60 * 1000
    for (let i = start; i <= end;) {
      i = i + 24 * 60 * 60 * 1000
      arr.push(i)
    }
    for (const j in arr) {
      const days = new Date(arr[j])
      this.dateList.push((days.getMonth() + 1) + '-' + days.getDate() + '（' + this.getWeekDay(String(days)) + '）')
    }
  }

  getWeekDay (time: string) {
    const weekNum = new Date(time).getDay()
    const week = [
      '周日',
      '周一',
      '周二',
      '周三',
      '周四',
      '周五',
      '周六'
    ]
    return week[weekNum]
  }

  // 日期选择限制
  pickerOptions: any = {
    onPick: (date: {maxDate: string; minDate: any}) => {
      this.pickMinTime = date.minDate.getTime()
    },
    // 限制范围最大31天
    disabledDate: (time: any) => {
      if (this.pickMinTime) {
        const one = 30 * 24 * 3600 * 1000
        const minTime: number = this.pickMinTime - one
        const maxTime: number = this.pickMinTime + one
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.worker.selectSchedulingCalendarPage, {
      ...this.info,
      startDate: this.dateRange ? this.dateRange[0] || '' : '',
      endDate: this.dateRange ? this.dateRange[1] || '' : '',
      page: this.page,
      size: this.size
    }).then(res => {
      this.getDateList()
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
